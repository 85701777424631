/* ==================
	9.1 Header
=================== */
.menu-logo {
    display: table;
    float: left;
    vertical-align: middle;
    padding: 0;
    color: #EFBB20;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    width: 200px;
    height: 105px;
    position: relative;
    z-index: 9;
	a{
		display: table-cell;
		vertical-align: middle;
	}
	img {
		height: auto;
		width: 100%;
	}
	// Text Logo
	span {
		font-size: 20px;
		letter-spacing: 20px;
	}
	@include respond('tab-port'){
		height: 85px;
	}
	@include respond('phone-land'){
		width: 180px;
	}
	@include respond('phone'){
		height: 75px;
		width: 150px;
	}
}

// Header
.header {
	position:relative;
	z-index:9999;
	.menu-bar {
		background: #fff;
		width: 100%;
		position: relative;
	}
	.container{
		position: relative;
	}
	
	.secondary-menu {
		float: right;
		height: 105px;
		display: flex;
		align-items: center;
		position: relative;
		z-index: 9;
		padding-left: 15px;
		
		.search-btn{
			margin-right: 35px;
			.btn-link{
				font-size: 25px;
				line-height: 25px;
				color: #565acf;
				cursor: pointer;
				border: 0;
				padding: 8px 0;
				background:none;
			}
		}
		ul{
			margin-bottom: 0;
			display: flex;
			align-items: center;
			
			.num-bx{
				margin-right:40px;
				a{
					font-weight: 700;
					font-size: 20px;
					color: #1f2278;
					display: flex;
					align-items: center;
					i{
						width: 42px;
						height: 42px;
						text-align: center;
						line-height: 42px;
						background-color: rgba($primary,0.1);
						font-size: 18px;
						border-radius: $border-radius-base;
						color:$primary;
						margin-right: 15px;
					}
				}
			}
		}
		@include respond('laptop'){
			.search-btn{
				margin-right: 25px;
			}
			ul{
				.num-bx{
					margin-right:30px;
				}
			}
		}
		@include respond('laptop-small'){
			ul{
				.num-bx{
					display:block;
				}
			}
		}
		@media (min-width: 0px) and (max-width: 1114px) {
			ul .num-bx {
				display: none;
			}
		}
		@include respond('tab-port'){
			height: 85px;
			.btn-area{
				display: none;
			}
			.search-btn{
				margin-right: 0;
			}
		}
		@include respond('phone'){
			height: 75px;
		}
	}	
}

// Navbar Toggler
.navbar-toggler {
    border: 0 solid #efbb20;
    font-size: 16px;
    line-height: 24px;
    margin: 35px 0 32px 20px;
    padding: 0;
	float: right;
	outline: none;
	@include respond('phone'){
		margin: 28px 0 28px 20px;
	}
}

// Menuicon
.menuicon{
	width: 20px;
	height: 18px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
	
	span{
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		border-radius: 1px;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-o-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
		background: #000;
		
		&:nth-child(1) {
			top: 0px;
		}

		&:nth-child(2) {
			top: 7px;
		}
		&:nth-child(3) {
			top: 14px;
		}
		
	}
	
	&.open span:nth-child(1) {
	  top: 7px;
	  -webkit-transform: rotate(135deg);
	  -moz-transform: rotate(135deg);
	  -o-transform: rotate(135deg);
	  transform: rotate(135deg);
	}
	&.open span:nth-child(2) {
	  opacity: 0;
	  left: -60px;
	}
	&.open span:nth-child(3) {
		top: 7px;
		-webkit-transform: rotate(-135deg);
		-moz-transform: rotate(-135deg);
		-o-transform: rotate(-135deg);
		transform: rotate(-135deg);
	}
}
.header-tb .menuicon span{
	background:#fff;
}

// Menu Links
.menu-links {
    padding: 0;
	
	&.show {
		@include respond('tab-port'){
			left: -1px;
			@include transitionSlow;
			margin:0;
			width: 280px;
		}
	}
	.menu-logo{
		display: none;
		@include respond('tab-port'){
			padding: 30px 20px;
			float: none;
			height: auto;
			width: 240px;
		}
	}
	.nav{
		float: right;
		padding: 0;
		
		&>li {
			margin: 0px;
			font-weight: 400;
			position: relative;
			&.has-mega-menu {
				position: inherit;
			}
			@include respond('tab-port'){	
				border-bottom: 1px solid #eee;	
				width: 100%;	
			}
			
			&.active > a{
				color: $secondary;
			}
			&>a {
				color: #1f2278;
				font-size: 19px;
				padding: 45px 20px;
				cursor: pointer;
				font-weight: 600;
				display: inline-block;
				position: relative;
				border-width: 0 0 0 1px;
				line-height: 1;
				i{
					font-size: 10px;
					margin-left: 5px;
					margin-top: -3px;
					vertical-align: middle;
					color:#bbbbcf;
					transition: all 0.5s;
					
					@include respond('tab-port'){	
						right: 0;
						text-align: center;
						z-index: 3;
						float: right;
						margin: 10px 5px 0 0;
						font-size: 12px;
						
						
					}
				}
				@include respond('tab-land'){
					padding: 45px 15px;
				}
				@include respond('tab-port'){	
					padding: 8px 0;
					display: block;	
					line-height: 30px;	
					color:#fff;	
				}
			}
			.mega-menu,
			.sub-menu {
				background-color: #ffffff;
				display: block;
				left: 0;
				list-style: none;
				opacity: 0;
				padding: 5px 0;
				position: absolute;
				visibility: hidden;
				width: 220px;
				z-index: 10;
				margin-top: 20px;
				box-shadow:0px 1px 40px 0px 
				rgba(0, 0, 0, 0.1);
				@include transitionSlow;
				@include respond('tab-port'){	
					display: none;
					position: static;
					visibility: visible;
					width: auto;
					background: transparent;
					box-shadow: none;
					margin: 0;
					border-top:1px solid rgba(255, 255, 255, 0.1);
					padding:0;
				}
				
				li {
					position: relative;
					a {
						color: #666;
						display: block;
						font-size: 14px;
						padding:10px 25px;
						text-transform: capitalize;
						position:relative;
						transition: all 0.15s linear;
						-webkit-transition: all 0.15s linear;
						-moz-transition: all 0.15s linear;
						-o-transition: all 0.15s linear;
						font-weight: 500;
						
						span{
							position: relative;
							&:after{
								content: "";
								position: absolute;
								height: 1px;
								width: 0;
								background: $primary;
								bottom: 0;
								right: 0;
								@include transitionMedium;
								-moz-transform-origin: right;
								-webkit-transform-origin: right;
								-ms-transform-origin: right;
								-o-transform-origin: right;
								transform-origin: right;
							}
						}
						&:hover{
							color: $primary;
							span{
								&:after {
									width: 100%;
									-moz-transform-origin: left;
									-webkit-transform-origin: left;
									-ms-transform-origin: left;
									-o-transform-origin: left;
									transform-origin: left;
									left: 0;
									right: auto;
								}
							}
						}
						
						@include respond('tab-port'){
							padding:10px 0px 10px 20px;
							border-bottom:1px solid rgba(255,255,255,0.1);
							span{
								&:after {
									content: none;
								}
							}
						}
					}
					&:hover > a{
						color: $primary;
					}
				}
				li>.sub-menu {
					left: 220px;
					@include transitionMedium;
				}
			}
			.sub-menu{
				li .fa {
					color: inherit;
					display: block;
					float: right;
					font-size: 9px;
					opacity: 1;
					margin-top: 4px;	
				}				
			}
			.mega-menu {
				display: flex;
				right: 0px;
				width: 100%;
				z-index: 9;
				padding: 0;
				&>li {
					display: block;
					padding: 30px 0 25px;
					position: relative;
					vertical-align: top;
					width: 25%;
					@include respond('tab-port'){
						width: 100%;
						padding:0;
					}
					&>a {
						color: #000;
						display: block;
						padding: 0 20px;
						font-size: 16px;
						font-weight: 600;
						border-bottom: 1px solid rgba(0,0,0,0.1);
						padding-bottom: 15px;
						&>i{
							font-size: 14px;
							margin-right: 5px;
							text-align: center;
							width: 15px;
						}
						@include respond('tab-port'){
							display:none;
						}
					}
					&:after {
						content: "";
						background-color: rgba(0, 0, 0, 0.1);
						position: absolute;
						right: 0px;
						top: 0px;
						display: block;
						width: 1px;
						height: 100%;
						@include respond('tab-port'){
							content:none;
						}
					}
					&:last-child:after {
						display: none;
					}
					&:hover > a{
						background-color: transparent;
					}
					li i{
						font-size: 14px;
						margin-right: 5px;
						text-align: center;
						width: 15px;
					}
				}
				@include respond('tab-port'){	
					display: none;
					padding:10px 0;
				}
			}
			&:hover{
				&>.mega-menu,
				&>.sub-menu{
					opacity: 1;
					visibility: visible;
					margin-top: 0;
					@include transitionMedium;
					
					li:hover > .sub-menu{
						opacity: 1;
						visibility: visible;
						margin-top: 0;
						top: -1px;
					}
				}
				a{
					color:$secondary;
					i{
						transform: rotate(45deg);
						color:$secondary;						
					}
				}
			}
			@include respond('tab-port'){
				&:hover{
					a{
						i{
							transform: unset;
						}
					}
				}
			}
			&.open{
				.mega-menu,
				.sub-menu{
					display:block;
					opacity:1;
				}
				&>a {
					i{
						@include respond('tab-port'){
							&:before{
								content: "\f068";
							}
						}
					}
				}
				
			}
		}
		@include respond('laptop'){
			margin-right:0;
		}
		@include respond('tab-port'){
			float: none;
			width: 70%;
			min-width: 300px;
		}
	}
	.social-media{
		text-align: center;
		margin-top: 50px;
		margin: 0 -3px;
		display: none;
		li{
			display: inline-block;
			padding: 0 3px;
			
			a{
				height: 40px;
				width: 40px;
				padding: 0;
				line-height: 38px;
			}
		}
	}
	.menu-close{
		height: 40px;
		color: #fff;
		background-color: $primary;
		line-height: 44px;
		text-align: center;
		width: 40px;
		position: absolute;
		right: 0;
		top: 0;
		display: none;
		z-index: 9999;
	}
	@include respond('tab-port'){
		overflow-y: scroll;
		position: fixed;
		width: 60px;
		left:-280px;
		height:100vh;
		@include transitionMedium;
		top:0;
		background-color:#fff;
		margin:0;
		z-index:99;
		
		.social-media,
		.menu-close{
			display: block;
		}
	}
}

// Top Ear
.top-bar {
    background-color: #191f23;
    color: #fff;
    padding: 8px 0;
	
	@include respond('phone'){
		display:none;
	}
	.topbar-left {
		float: left;
		li{
			padding-right:15px;
		}
	}
	.topbar-right {
		float: right;
		li{
			padding-left:15px;
		}
	}
	
	.topbar-center,
	.topbar-left,
	.topbar-right {
		padding-left: 15px;
		padding-right: 15px;
		ul{
			list-style:none;
			margin:0;
			padding:0;
			li{
				display:inline-block;
				font-size: 15px;
				@include respond('tab-land'){
					font-size: 13px;
				}
			}
			a{
				color:inherit;
			}
			i{
				color:$primary;
				transform: scale(1.3);
				-moz-transform: scale(1.3);
				-webkit-transform: scale(1.3);
				-ms-transform: scale(1.3);
				-o-transform: scale(1.3);
				margin-right: 5px;
			}
		}
	}
}

// Header Transparent
.header-transparent{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	&.style-1{
		.menu-links {
			.nav{
				&>li {
					&>a {
						color:#1f2278;	
						&:hover{
							color:$secondary;
							i{
								transform: rotate(45deg);
								color:$secondary;
							}
						}
					}	
				}	
			}	
		}	
	}
	&.style-2{
		.menu-links {
			.nav{
				&>li {
					&>a {
						color:#fff;
						&:hover{
							color:$white;
						}
					}	
				}	
			}	
		}	
	}
	.top-bar,
	.menu-bar{
		background: transparent;
	}
	.top-bar{
	    color: #777;
		border-bottom:1px solid rgba(0,0,0,0.1);
		@include respond('phone'){
			display:none;
		}
	}
}

// Sticky Header
.sticky-header.active{
	.menu-logo{
		@include durationMedium;
	}
	.menu-bar{
		position: fixed;
		top: 0;
		left: 0;
		box-shadow:0 0 50px 0 rgba(0, 0, 0, 0.1);
	}
	.logo-change{
		.logo1{
			display:none;
		}
		.logo2{
			display: block;
		}
	}
}
.header-transparent{
	.sticky-header.active{
		.menu-bar{
			position:fixed;
			background-color:#fff;
		}
	}
} 


// Responsive Size Nav
@include respond('tab-port'){
	.rs-nav{
		.menu-links{
			position: fixed;
			width: 60px;
			left:-105%;
			height:100vh;
			@include transitionMedium;
			top:0;
			background-color:#171a1e;
			margin:0;
			z-index:99;
			overflow-y: scroll;
			flex-direction: column;
			display: flex;
			flex-direction: column;
			justify-content: unset !important;	
			    
			li.open a{
				position:relative;
			}
			.menu-logo {
				display: block;
				float: none;
				height: auto;
				max-width: 100%;
				padding: 20px 15px;
				width: 100%;
				text-align: center	;
				img{
					max-width: unset;
					width: 150px;
					vertical-align: middle;
				}
				a{
					display:inline-block;
				}
			}
			
			&.nav-dark{
				background-color:#202020;
			}
			&.show {
				left: 0;
				@include transitionSlow;
				margin:0;
				width: 100%;
				padding: 15px 15px 5px 15px;
			}
		}
		.sticky-header.active{
			.menu-links{
				.nav{
					height:auto;
				}
			}
		}
		.social-media{
		    margin-top: auto;
			padding: 30px 0;
		}
	}
	.navbar-nav{
		height:auto;
	}
	
	.menu-links{
		.nav > li{
			border-color: rgba(255,255,255,0.1);
			a{
				font-size: 16px;
				color: #fff;
			}
			&:hover,
			&.active{
				& > a{
					color:#fff;
					border-color: rgba(255,255,255,0.1);
				}
			}
			.mega-menu li a, 
			.sub-menu li a{
				color:rgba(255,255,255,0.7);
			}
			.mega-menu li:hover > a, 
			.sub-menu li:hover > a {
				background-color: transparent;
			}
		}
	}
	.header-transparent .sticky-header.active .menu-links .nav > li > a {
		color: #fff;
	}
}

.nav-search-bar {
    background-color:rgba(255,255,255,0.95);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
	height:100%;
    z-index: 999;
    display: none;
    overflow: hidden;
	padding: 0 15px;
	
	& > span {
		position: absolute;
		right: 15px;
		top: 15px;
		font-size: 14px;
		cursor: pointer;
		color: #fff;
		background-color: $primary;
		height: 40px;
		width: 40px;
		text-align: center;
		line-height: 44px;
	}
	&.show{
		display: block;
		form {
			transition:all 0.5s ease 0.5s;
			-moz-transition:all 0.5s ease 0.5s;
			-webkit-transition:all 0.5s ease 0.5s;
			opacity:1;
		}
	}
	form {
		width: 100%;
		max-width: 700px;
		margin: auto;
		position: relative;
		top: 50%;
		transition:all 0.5s;
		transition:all 0.5s;
		-webkit-transition:all 0.5s;
		-moz-transform: translate(0px, -50%);
		-moz-transform: translate(0px, -50%);
		-o-transform: translate(0px, -50%);
		-webkit-transform: translate(0px, -50%);
		opacity:0;
		
		span {
			position: absolute;
			right: 15px;
			top: 50%;
			margin: -15px 0;
			height: 25px;
			font-size: 20px;
			cursor: pointer;
			color: #000;
		}
	}
	.form-control {
		padding: 15px 60px 15px 15px;
		width: 100%;
		height: 70px;
		border: none;
		background: none;
		color: #000;
		font-size: 20px;
		border-bottom:2px solid #000;
		border-radius: 0;
			
		&::-moz-placeholder{
			color: #000;
		}
		&:-moz-placeholder{
			color: #000;
		}
		&:-ms-input-placeholder{
			color: #000;
		}
		&::-webkit-input-placeholder{
			color: #000;
		}
	}
}
